import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useState } from 'react';
import * as mui from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import emailjs from '@emailjs/browser';

const darkTheme = mui.createTheme({
  palette: {
    mode: 'dark',
  },
});

const Item = mui.styled(mui.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const FormularioContacto = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    miNombre: 'Juan Pedro',
    nombre: '',
    email: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  isSubmitting && console.log(formData);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.nombre.trim() || !formData.email.trim() || !formData.mensaje.trim()) {
      return;
    }

    setIsSubmitting(true);

    emailjs.init({
      publicKey: 'ZNCJahy9aRX9SKV6f',
      limitRate: {
        throttle: 10000000
      }
    });

    emailjs.send(
      'service_h0l3muv',
      'template_hdue41m',
      formData
    ).then((result) => {
      console.log(result.text);
      setOpen(true);
    }, (error) => {
      console.log(error.text);
    }).finally(() => {
      setIsSubmitting(false);
    });

    setFormData({
      nombre: '',
      email: '',
      mensaje: ''
    });
  };

  const emailValido = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
  const camposVacios = formData.nombre.trim() !== '' && formData.email.trim() !== '' && formData.mensaje.trim() !== '';

  return (
    <mui.Grid sx={{ minWidth: '40%' }}>
      <mui.Grid item xs={12} md={6}>
        <mui.Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={2} >
          <mui.TextField
            label="Nombre"
            name="nombre"
            variant="outlined"
            value={formData.nombre}
            onChange={handleChange}
            fullWidth
          />
          <mui.TextField
            error={!emailValido && formData.email.trim() !== ''}
            helperText={!emailValido && formData.email.trim() !== '' ? 'Introduce un email válido' : ''}
            label="Correo"
            name="email"
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            fullWidth
          />
          <mui.TextField
            label="Mensaje"
            name="mensaje"
            variant="outlined"
            value={formData.mensaje}
            onChange={handleChange}
            multiline
            rows={8}
            fullWidth
          />
          <mui.Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!emailValido || !camposVacios || isSubmitting}
          >
            Enviar
          </mui.Button>

          <mui.Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-confirmacion-email"
            aria-describedby="dialog-descripcion-email"
          >
            <mui.DialogTitle id="confirmacion-email">
              {"Confirmación de envío"}
            </mui.DialogTitle>
            <mui.DialogContent>
              <mui.DialogContentText id="description-email">
                Se ha enviado el email correctamente. ¡Gracias por contactar conmigo!
              </mui.DialogContentText>
            </mui.DialogContent>
            <mui.DialogActions>
              <mui.Button onClick={handleClose} autoFocus>Aceptar</mui.Button>
            </mui.DialogActions>
          </mui.Dialog>
        </mui.Box>
      </mui.Grid>
    </mui.Grid>
  );
};

function Header() {
  return (
    <mui.AppBar position="sticky">
      <mui.Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <mui.Typography variant="h4" component="h1">
          Juan Pedro Moreno
        </mui.Typography>

        <mui.Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <a href='#inicio' style={{ textDecoration: 'none', color: 'inherit' }}>
            <mui.Button color="inherit">Inicio</mui.Button>
          </a>
          <a href="#sobre-mi" style={{ textDecoration: 'none', color: 'inherit' }}>
            <mui.Button color="inherit">Sobre mi</mui.Button>
          </a>
          <a href="#proyectos" style={{ textDecoration: 'none', color: 'inherit' }}>
            <mui.Button color="inherit">Proyectos</mui.Button>
          </a>
          <a href="#contacto" style={{ textDecoration: 'none', color: 'inherit' }}>
            <mui.Button color="inherit">Contacto</mui.Button>
          </a>
        </mui.Box>
      </mui.Toolbar>
    </mui.AppBar>
  );
}

function Footer() {
  return (
    <mui.AppBar component="footer" position="static" display="md">
      <mui.Toolbar >
        <mui.Typography variant="h6" component="h2" sx={{ flexGrow: 1 }}>
          Juan Pedro Moreno
        </mui.Typography>

        <mui.Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <mui.Typography variant="h6" component="h2">
            Redes
          </mui.Typography>

          <mui.Box sx={{ display: 'flex', justifyContent: 'center', gap: 0.25 }}>
            <mui.IconButton component="a" href="https://github.com/Karylus" target="_blank">
              <GitHubIcon />
            </mui.IconButton>
            <mui.IconButton component="a" href="https://www.linkedin.com/in/juanpemoreno/" target="_blank">
              <LinkedInIcon />
            </mui.IconButton>
          </mui.Box>
        </mui.Box>
      </mui.Toolbar>
    </mui.AppBar>
  );
}

function Inicio() {
  return (
    <mui.Container id="inicio" sx={{ visibility: 'hidden', height: 0, overflow: 'hidden' }}>
    </mui.Container>
  );
}

function SobreMI() {
  return (
    <mui.Container id="sobre-mi" maxWidth="xl" sx={{ paddingTop: 5 }} component={'section'}>
      <mui.Typography variant="h3" component="h2" textAlign="left">
        Sobre mi
      </mui.Typography>

      <mui.Box display="flex" justifyContent="center" borderBottom={2}>
        <mui.Typography variant="h4" paragraph textAlign="center" sx={{ width: '60%' }} >
          ¡Hola! Soy Juan Pedro, sigue leyendo para saber más sobre qué hago y mis habilidades.
        </mui.Typography>
      </mui.Box>

      <mui.Box component="section" display="flex" alignItems="Center" justifyContent="space-around" marginTop={3}>
        <mui.Typography component="p" sx={{ width: '40%' }}>
          Estudiante del Doble Grado de Ingeniería Informática y ADE en la UGR, cuento con una formación integral que
          me permite combinar mis habilidades técnicas con mis capacidades analíticas y de gestión empresarial.
          Soy una persona proactiva, organizada y con gran capacidad para aprender. Me gusta trabajar en equipo
          y soy capaz de adaptarme fácilmente a nuevos entornos y desafíos. <br /><br />

          Actualmente estoy buscando prácticas en el ámbito de la programación para seguir aprendiendo y
          desarrollándome profesionalmente. <br /><br />

          Si quieres saber más sobre mi, puedes visitar mi perfil de LinkedIn o mi GitHub.
        </mui.Typography>

        <mui.Stack direction="row" spacing={2} display="flex" useFlexGap flexWrap="wrap" sx={{ width: '40%' }}>
          <Item>C++</Item>
          <Item>C#</Item>
          <Item>Python</Item>
          <Item>React</Item>
          <Item>HTML</Item>
          <Item>CSS</Item>
          <Item>PHP</Item>
          <Item>SQL</Item>
          <Item>OracleDB</Item>
          <Item>Git</Item>
          <Item>GitHub</Item>
          <Item>Linux</Item>
          <Item>Desarrollo Ágil</Item>
          <Item>SCRUM</Item>
          <Item>Trabajo en equipo</Item>
          <Item>Resolución de problemas</Item>
          <Item>Adaptabilidad</Item>
        </mui.Stack>
      </mui.Box>
    </mui.Container>
  );
}

function Proyectos() {
  return (
    <mui.Container id="proyectos" maxWidth="xl" sx={{ paddingTop: 5 }} component={'section'}>
      <mui.Typography variant="h3" component="h2" paddingBottom={3}>
        Proyectos
      </mui.Typography>

      <mui.Grid container spacing={4}>
        <mui.Grid item xs={12} md={4}>
          <mui.Card>
            <mui.CardMedia
              component="img"
              height="200"
              image="../simpleCalculator.png"
              alt="Simple Calculator"
            />
            <mui.CardContent>
              <mui.Typography gutterBottom variant="h5" component="div">
                Simple Calculator
              </mui.Typography>
              <mui.Typography variant="body1" color="text.secondary">
                Una calculadora muy simple creada con Windows Forms en C# que hice para practicar el
                lenguaje, la interfaz gráfica y el control de eventos.
              </mui.Typography>

              <mui.CardActions>
                <mui.Button size="small" href="https://github.com/Karylus/SimpleCalculator" target="_blank">
                  Ver en GitHub
                </mui.Button>
              </mui.CardActions>
            </mui.CardContent>
          </mui.Card>
        </mui.Grid>

        <mui.Grid item xs={12} md={4}>
          <mui.Card>
            <mui.CardMedia
              component="img"
              height="200"
              image="../stivenBot.webp"
              alt="StivenBot"
            />
            <mui.CardContent>
              <mui.Typography gutterBottom variant="h5" component="div">
                StivenBot
              </mui.Typography>
              <mui.Typography variant="body1" color="text.secondary">
                Un bot de Discord contruido en Python para la comunidad de JDriix,
                un streamer de Twitch. Usa comandos, reacciones y eventos para interactuar.
              </mui.Typography>

              <mui.CardActions>
                <mui.Button size="small" href="https://github.com/Karylus/StivenBot" target="_blank">
                  Ver en GitHub
                </mui.Button>
              </mui.CardActions>
            </mui.CardContent>
          </mui.Card>
        </mui.Grid>

        <mui.Grid item xs={12} md={4}>
          <mui.Card>
            <mui.CardMedia
              component="img"
              height="200"
              image="../hotelM&M.webp"
              alt="Hotel M&M"
            />
            <mui.CardContent>
              <mui.Typography gutterBottom variant="h5" component="div">
                Hotel M&M
              </mui.Typography>
              <mui.Typography variant="body1" color="text.secondary">
                Sitio web de un hotel ficticio creado con HTML, Tailwind, PHP y JS para la asignatura de
                Tecnologías Web en la Universidad de Granada.
              </mui.Typography>

              <mui.CardActions>
                <mui.Button size="small" href="https://github.com/Karylus/ProyectoTW" target="_blank">
                  Ver en GitHub
                </mui.Button>
              </mui.CardActions>
            </mui.CardContent>
          </mui.Card>
        </mui.Grid>
      </mui.Grid>
    </mui.Container>
  );
}

function Contacto() {
  return (
    <mui.Container id="contacto" component={'section'} maxWidth="xl" sx={{ paddingTop: 5 }}>
      <mui.Typography variant="h3" component="h2" paddingBottom={3}>
        Contacto
      </mui.Typography>

      <mui.Box display="flex" justifyContent="left" borderBottom={2}>
        <mui.Typography variant="h5" paragraph sx={{ width: '80%' }}>
          ¿Quieres contactar conmigo? Puedes hacerlo a través del siguiente formulario o de mis redes sociales.
        </mui.Typography>
      </mui.Box>

      <mui.Box display="grid" gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }} paddingY={3} gap={2}>
        <FormularioContacto />

        <mui.Grid item xs={12} md={6} sx={{ width: { xs: '100%', sm: '100%' } }}>
          <mui.Box display="flex" flexDirection="column" gap={2} justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <mui.Typography variant="h5" component="h3">
              Redes sociales
            </mui.Typography>

            <mui.Box display="flex" gap={2}>
              <mui.Tooltip title="GitHub" arrow>
                <mui.IconButton component="a" href="https://github.com/Karylus" target="_blank" style={{ fontSize: 'large' }}>
                  <GitHubIcon style={{ fontSize: '40px' }} />
                </mui.IconButton>
              </mui.Tooltip>

              <mui.Tooltip title="LinkedIn" arrow>
                <mui.IconButton component="a" href="https://www.linkedin.com/in/juanpemoreno/" target="_blank" style={{ fontSize: 'large' }}>
                  <LinkedInIcon style={{ fontSize: '40px' }} />
                </mui.IconButton>
              </mui.Tooltip>
            </mui.Box>
          </mui.Box>
        </mui.Grid>
      </mui.Box>
    </mui.Container>
  );
}

function App() {
  return (
    <mui.ThemeProvider theme={darkTheme}>
      <mui.CssBaseline />
      <Header />

      <Inicio />
      <SobreMI />
      <Proyectos />
      <Contacto />

      <Footer />
    </mui.ThemeProvider>
  );
}

export default App;
